import { React, useEffect, useState } from "react";
import { AppBar, Badge, Card, Grid, Tab, Tabs } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';


import EditApp from './Edit';
import Database from './install/Database';
import Config from './install/Config';
import ArtisanCommand from './install/ArtisanCommand';
import UserSetup from './install/User';
import SuperAdminUserSetup from './super_admin_users/Index';
import DocumentSetup from './install/Document';
import EmailSetting from './install/EmailSetting';
import Status from './install/Status';
import InvoiceOptions from './install/InvoiceOptions/index';
import callFetch from "helpers/callFetch";

import curved0 from "assets/images/curved-images/curved0.jpg";

function Install() {
    let params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState('companyprofile');
    const [app, setApp] = useState({});

    useEffect(() => {
        if (!params?.tab) return;


        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setApp(res?.data)
            if (res?.data?.installed === 1 || params?.tab === 'companyprofile' || params?.tab === 'install') {
                setCurrentTab(params?.tab)
            } else {
                console.log('You have to install first')
            }
        });
    }, [params?.id, params?.tab]);

    return (
        <>
            <SoftBox position="relative">
                <SoftBox
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="12.75rem"
                    borderRadius="xl"
                    sx={{
                        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.info.main, 0.6),
                                rgba(gradients.info.state, 0.6)
                            )}, url(${curved0})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                    }}
                />
                <Card
                    sx={{
                        backdropFilter: `saturate(200%) blur(30px)`,
                        backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                        boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                        position: "relative",
                        mt: -8,
                        mx: 3,
                        py: 2,
                        px: 2,
                    }}
                >
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <SoftAvatar
                                src={'/assets/img/placeholder.png'}
                                alt="profile-image"
                                variant="rounded"
                                size="xl"
                                shadow="sm"
                            />
                        </Grid>
                        <Grid item>
                            <SoftBox height="100%" mt={0.5} lineHeight={1}>
                                <SoftTypography variant="h5" fontWeight="medium">
                                    {app?.company_name ? app?.company_name : '---'}
                                </SoftTypography>
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                    {app?.id ? app?.id : '---'}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </Card>
            </SoftBox>

            <SoftBox mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={3}>
                        <Card
                            sx={{
                                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                                position: "sticky",
                                top: "10%",
                            }}
                        >
                            <SoftBox>
                                <div class="nav flex-column nav-pills me-3 settings-nav" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'companyprofile' ? 'active' : '')}
                                        type="button"
                                        onClick={() => navigate('/apps/' + params?.id + '/companyprofile')}
                                    >
                                        {t('Company Profile')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'install' ? 'active' : '')}
                                        type="button"
                                        onClick={() => navigate('/apps/' + params?.id + '/install')}
                                    >
                                        {t('Database')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'config' ? 'active' : '')}
                                        type="button"
                                        onClick={() => navigate('/apps/' + params?.id + '/config')}
                                    >
                                        {t('Config')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'artisan' ? 'active' : '')}
                                        type="button"
                                        onClick={() => navigate('/apps/' + params?.id + '/artisan')}
                                    >
                                        {t('Artisan Command')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'user_setup' ? 'active' : '')}
                                        type="button"
                                        //onClick={() => setCurrentTab('user_setup')}
                                        onClick={() => navigate('/apps/' + params?.id + '/user_setup')}
                                    >
                                        {t('User')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'super_admin_user_setup' ? 'active' : '')}
                                        type="button"
                                        //onClick={() => setCurrentTab('super_admin_user_setup')}
                                        onClick={() => navigate('/apps/' + params?.id + '/super_admin_user_setup')}
                                    >
                                        {t('Super Admn User')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'document_setup' ? 'active' : '')}
                                        type="button"
                                        //onClick={() => setCurrentTab('document_setup')}
                                        onClick={() => navigate('/apps/' + params?.id + '/document_setup')}
                                    >
                                        {t('Document')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'email_setup' ? 'active' : '')}
                                        type="button"
                                        //onClick={() => setCurrentTab('email_setup')}
                                        onClick={() => navigate('/apps/' + params?.id + '/email_setup')}
                                    >
                                        {t('Email Settings')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'status' ? 'active' : '')}
                                        type="button"
                                        //onClick={() => setCurrentTab('email_setup')}
                                        onClick={() => navigate('/apps/' + params?.id + '/status')}
                                    >
                                        {t('Status')}
                                    </button>

                                    <button
                                        class={"nav-link font-size-14-rem text-start " + (currentTab == 'invoice-option' ? 'active' : '')}
                                        type="button"
                                        //onClick={() => setCurrentTab('email_setup')}
                                        onClick={() => navigate('/apps/' + params?.id + '/invoice-option')}
                                    >
                                        {t('Invoice Option')}
                                    </button>
                                </div>
                            </SoftBox>
                        </Card>

                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <SoftBox mb={3}>
                            <Grid container spacing={3}>
                                <div class="tab-content" id="v-pills-tabContent" style={{ padding: '20px 0px 20px 20px', width: '100%' }}>
                                    <div class="tab-pane fade show active">
                                        <Grid item xs={12} lg={12}>
                                            {currentTab == 'companyprofile' ? (
                                                <EditApp />
                                            ) : currentTab == 'install' ? (
                                                <Database />
                                            ) : currentTab == 'config' ? (
                                                <Config />
                                            ) : currentTab == 'artisan' ? (
                                                <ArtisanCommand />
                                            ) : currentTab == 'user_setup' ? (
                                                <UserSetup />
                                            ) : currentTab == 'super_admin_user_setup' ? (
                                                <SuperAdminUserSetup />
                                            ) : currentTab == 'document_setup' ? (
                                                <DocumentSetup />
                                            ) : currentTab == 'email_setup' ? (
                                                <EmailSetting />
                                            ) : currentTab == 'status' ? (
                                                <Status />
                                            ) : currentTab == 'invoice-option' ? (
                                                <InvoiceOptions />
                                            ) : ''}
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
}

export default Install;
